import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Layout from '../components/layout/Index'
import FullPage from '../components/full-page/Index'

import applyRouters from './applys'
import userRouters from './users'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: FullPage,
        meta: {
          title: '首页'
        }
      },
      {
        path: 'products',
        name: 'products',
        component: FullPage,
        meta: {
          title: '产品生态'
        }
      },
      {
        path: 'solution',
        name: 'solution',
        component: FullPage,
        meta: {
          title: '解决方案'
        }
      },
      {
        path: 'partner',
        name: 'partner',
        component: FullPage,
        meta: {
          title: '合作伙伴'
        }
      },
      {
        path: 'about',
        name: 'about',
        component: FullPage,
        meta: {
          title: '关于我们'
        }
      },
      ...applyRouters.routers,
      ...userRouters.routers
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Index'),
    meta: {
      title: '登录'
    }
  }
]

const router = new VueRouter({
  routes
})

// 路由钩子函数
router.afterEach(to => {
  // window.scrollTo(0, 0)
  if (to.name !== 'login') {
    store.dispatch('setRouterName', to.name)
  }
})

export default router
