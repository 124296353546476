<template>
  <div class="st-main st-base-bg">
    <div class="product-wrapper">
      <div class="st-title">
        <b>新大陆智能溯源生态</b>
        <p>食安云与企业深度合作，创造数字化未来</p>
      </div>
      <ul class="tab-items">
        <li
          v-for="(item, index) in tabs"
          :key="index"
          :class="{ active: tabActive == index }"
          @click="selectTab(index, item)">
          {{ item.name }}
        </li>
      </ul>
      <div class="main">
        <food v-show="tabActive == 0" />
        <cook v-show="tabActive == 1" />
        <money v-show="tabActive == 2" />
      </div>
    </div>
  </div>
</template>

<script>
import Food from './components/food/Index'
import Cook from './components/cook/Index'
import Money from './components/money/Index'

export default {
  components: {
    Food,
    Cook,
    Money
  },
  data() {
    return {
      tabActive: 0,
      tabs: [
        { name: '食神通', id: 0 },
        { name: '厨神通', id: 1 },
        { name: '财神通', id: 2 }
      ]
    }
  },
  methods: {
    // 选择tab
    selectTab (index) {
      this.tabActive = index
    }
  }
}
</script>

<style lang="less" scoped>
  @import 'index';
</style>
