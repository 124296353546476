<template>
  <div class="st-main">
    <div class="partner-wrapper">
      <div class="st-title">
        <b>合作伙伴</b>
        <p>食安云与企业深度合作，创造数字化未来</p>
      </div>
      <div class="partner-main">
        <swiper :options="swiperOptions">
          <swiper-slide
            v-for="(items, index) in partnerItems"
            :key="index">
            <ul class="partner-items">
              <li
                v-for="(item, i) in items"
                :key="i">
                <div class="icon">
                  <img :src="item.icon" alt="">
                </div>
              </li>
            </ul>
          </swiper-slide>
          <div
            class="swiper-pagination"
            slot="pagination">
          </div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { partners } from '../../utils/items'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: '',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        pagination: { // 使用分页器导航
          el: '.swiper-pagination',
          clickable: true, // 点击分页器的指示点分页器会控制 Swiper 切换
          renderBullet: (index, className) => {
            return `<span class="${ className }" onmouseenter="mouseenter(${ index })"></span>`
          }
        },
        loop: true, // 循环模式
        autoplay: { // 自动切换
          delay: 4000,
          disableOnInteraction: false
        },
        enabled: true, // 使用键盘切换
        onlyInViewport: true, // 仅控制当前窗口内的swiper切换
        observer: true,
        observeParents: true
      },
      partnerItems: partners
    }
  },
  methods: {
    mouseenter (index) {
      console.log(index)
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
