<template>
  <div class="cook-wrapper">
    <product-cards
      :items="items"
      @item-details="itemDetails"
    />
    <product-show
      :item="item"
      @close="showClose"
      v-if="productShow"
    />
  </div>
</template>

<script>
import ProductCards from '../../../../components/product-cards/Index'
import ProductShow from '../../../../components/popup/product-show/Index'
import { moneyItems } from '../../../../utils/items'
import { mapState } from 'vuex'

export default {
  name: '',
  components: {
    ProductCards,
    ProductShow
  },
  data () {
    return {
      item: {},
      items: moneyItems
    }
  },
  computed: {
    ...mapState(['productShow'])
  },
  mounted () {
    this.item = this.items[0]
  },
  methods: {
    // 查看详情
    itemDetails (item) {
      this.item = item
      this.$store.dispatch('setProductShow', true)
    },
    // 关闭弹窗
    showClose () {
      this.$store.dispatch('setProductShow', false)
    }
  }
}
</script>
