<template>
  <div class="header-wrapper" :class="isScroll ? 'is-scroll' : ''">
    <h1 class="logo" @click="toHome">
      <img src="../../assets/images/logo.png" alt="" />
    </h1>

    <div class="nav-menu">
      <ul id="menu">
        <li
          v-for="(item, index) in navMenus"
          :key="index"
          :class="routerName == item.routerName ? 'is-active' : ''"
          @click="selectNav(item)"
        >
          {{ item.name }}
          <b></b>
        </li>
      </ul>

      <div class="user-wrapper">

        <el-popover
          placement="bottom-start"
          width="1.69rem"
          trigger="hover"
          popper-class="user-icon"
          class="main"
          v-if="isLogin === true">
          <ul>
            <li @click="userInfoBtn">个人中心</li>
            <li @click="logout">退出</li>
          </ul>
          <div class="user-info" slot="reference">
            <div class="icon">
              <img src="../../assets/images/base-user-icon.png" alt="" />
            </div>
            <p>{{ this.userInfo.name }}</p>
          </div>
        </el-popover>

        <div
          class="login-btn"
          @click="login"
          v-show="isLogin == false">
          立即登录
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { navMenus } from '../../utils/items'
import { mapState } from 'vuex'

export default {
  name: 'BaseHeader',
  data () {
    return {
      isLogin: this.st.user.isLogin,
      navMenus: navMenus,
      isScroll: false
    }
  },
  computed: {
    ...mapState(['routerName', 'userInfo']),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    toHome () {
      this.$router.push({ path: '/' })
    },

    // 选择tab事件
    selectNav(item) {
      item.id === 6
        ? window.open('https://easyv.dtstack.com/workspace/shareScreen/eyJzY3JlZW5JZCI6NTI1NDl9', '_blank')
        : this.$emit('item-tap', item)
    },

    // 点击用户
    userInfoBtn() {
      this.$router.push({ name: 'users' })
    },

    // 登录
    login() {
      this.$router.push({ name: 'login' })
    },

    // 点击退出登录按钮
    logout() {
      this.st.user.logout()
      this.isLogin = false
      this.$store.dispatch('setUserInfo', {})
      this.$router.push({ name: this.routerName })
    },

    handleScroll() {
      //获取滚动时的高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      scrollTop > 0
        ? this.isScroll = true
        : this.isScroll = false
    }
  }
}
</script>

<style lang="less" scoped>
  @import './index';
</style>
