<template>
  <div class="food-wrapper">
    <product-cards
      :items="items"
      @item-details="itemDetails"
    />
    <b class="apply-btn" @click="onApplyBtn">
      <s>申请入驻</s>
    </b>
    <product-show
      :item="item"
      @close="showClose"
      v-if="productShow"
    />
  </div>
</template>

<script>
import ProductCards from '../../../../components/product-cards/Index'
import ProductShow from '../../../../components/popup/product-show/Index'
import { foodItems } from '../../../../utils/items'
import { mapState } from 'vuex'

export default {
  name: '',
  components: {
    ProductCards,
    ProductShow
  },
  data () {
    return {
      isLogin: this.st.user.isLogin,

      item: {},
      items: foodItems
    }
  },
  computed: {
    ...mapState(['productShow'])
  },
  mounted () {
    this.item = this.items[0]
  },
  methods: {
    // 查看详情
    itemDetails (item) {
      this.item = item
      this.$store.dispatch('setProductShow', true)
    },
    // 关闭弹窗
    showClose () {
      this.$store.dispatch('setProductShow', false)
    },
    // 点击申请按钮
    onApplyBtn () {
      this.isLogin
        ? this.$router.push({
          name: 'apply'
        })
        : this.$router.push({
          name: 'login'
        })
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
