<template>
  <div class="popup-wrapper">
    <i class="el-icon-close close-btn" @click="close"></i>

    <!-- PC端 -->
    <div class="popop-main pc-main" v-if="item.type === 4">
      <div>
        <div class="item-container">
          <div class="item-header">
            <h3>{{ item.name }}</h3>
            <b>
              查看项目展示
              <i class="el-icon-arrow-right"></i>
            </b>
          </div>
        </div>

        <div class="st-swiper">
          <swiper
            class="swiper gallery-top"
            :options="swiperOptionTop"
            ref="swiperTop">
            <swiper-slide
              v-for="(item, index) in item.images"
              :key="index"
              :style="{ backgroundImage: 'url(' + item + ')' }"
            >
            </swiper-slide>
          </swiper>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>

          <div class="swiper-container gallery-thumbs">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in item.images"
                :key="index"
                :style="{ backgroundImage: 'url(' + item + ')' }"
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 移动端 -->
    <div class="popop-main phone-main" v-else>
      <div class="st-swiper">
        <swiper
          :options="swiperOptions"
          class="swiper-no-swiping"
          v-if="swiperShow">
          <swiper-slide
            v-for="(item, index) in item.images"
            :key="index"
          >
            <div class="phone-img" :style="{ backgroundImage: 'url(' + item + ')' }"></div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="item-container">
        <div class="item-header">
          <h3>{{ item.name }}</h3>
          <b>
            查看项目展示
            <i class="el-icon-arrow-right"></i>
          </b>
        </div>
        <div class="item-footer" v-if="item.type !== 3">
          <div class="qr-code">
            <img :src="item.qrcode" alt="">
          </div>
          <p>扫描二维码进入{{ item.type | typeFil }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: '',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    item: {
      type: Object
    }
  },
  data () {
    return {
      swiperShow: true,

      // 移动端 swiper 相关配置
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 'auto',
        spaceBetween: '11.32%',
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren:true,
        on: {
          resize: () => {
            this.init()
          }
        }
      },

      // PC端 swiper 相关配置
      swiperOptionTop: {
        spaceBetween: '6.58%',
        loop: true,
        loopedSlides: 5,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        thumbs: {
          swiper: {
            el: '.gallery-thumbs',
            spaceBetween: '6.58%',
            slidesPerView: 4,
            loop: true,
            freeMode: true,
            loopedSlides: 5,
            watchSlidesVisibility: true,
            watchSlidesProgress: true
          }
        }
      }
    }
  },
  filters: {
    typeFil (value) {
      let items = ['H5', '微信小程序', 'APP', 'PC']
      if (value <= items.length) {
        return items[value - 1]
      }
      return ''
    }
  },
  methods: {
    init () {
      this.swiperShow = false
      setTimeout(() => {
        this.swiperShow = true
      }, 400)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
