export const Config = {
  baseUrl: 'https://testing.smartracing.cn', // 溯源 api 地址
  ynApiUrl: 'https://demo.smartracing.cn', // 益农 api 地址
  ossUrl: 'https://static.smartracing.cn/images/st-website-pc', // oss 图片路径
  // 用户目录2.0 登录回调路径
  redirectUris: [
    'http://localhost:8866/#/login',
    'https://www.smartracing.cn/#/login',
    'http://test.website.smartracing.cn/#/login'
  ],
  // 项目 ID
  clientID: '5f90f09f964a8ce6feafbc8b'
}
