<template>
  <div class="full-page">
    <full-page
      ref="fullPage"
      :options="options"
      id="fullpage">
      <div class="section">
        <home />
      </div>
      <div class="section">
        <products />
      </div>
      <div class="section">
        <solution />
      </div>
      <div class="section">
        <collaborator />
      </div>
      <div class="section">
        <about />
      </div>
    </full-page>

    <i class="icon-scroll"></i>
  </div>
</template>

<script>
import Home from '../../views/home/Index'
import Products from '../../views/products/Index'
import Solution from '../../views/solution/Index'
import Collaborator from '../../views/partner/Index'
import About from '../../views/about/Index'
import { navMenus } from '../../utils/items'
import { mapState } from 'vuex'

export default {
  components: {
    Home,
    Products,
    Solution,
    Collaborator,
    About
  },
  data() {
    return {
      options: {
        verticalCentered: true, // 定义每一页的内容是否垂直居中
        afterLoad: this.afterLoad, // 滚动到某一屏后的回调函数
        scrollBar: true, // true 则是一滚动就是一整屏
        anchors: ['home', 'products', 'solution', 'partner', 'about'],
        menu: '#menu'
      }
    }
  },
  computed: {
    ...mapState(['productShow'])
  },
  watch: {
    $route: 'getRouterName',
    productShow (value) {
      value
        ? this.$refs.fullPage.api.setAllowScrolling(false)
        : this.$refs.fullPage.api.setAllowScrolling(true)
    }
  },
  mounted() {
    this.getRouterName()
  },
  methods: {
    afterLoad (anchors, item) {
      this.$store.dispatch('setRouterName', item.anchor)
    },
    getRouterName() {
      let menu = navMenus
      let n = menu.findIndex(item => item.routerName == this.$route.name)
      this.$refs.fullPage.api.moveTo(n + 1, true)
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
