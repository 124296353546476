<template>
  <div class="st-main">
    <div class="solution-wrapper">
      <swiper :options="swiperOptions">
        <swiper-slide
          v-for="(item, index) in items"
          :key="index"
          :class="item.name">
          <div class="solution-item">
            <div class="item-main">
              <h3 class="st-title">
                <b>{{ item.title }}</b>
              </h3>
              <p>{{ item.text }}</p>
              <div class="btn">
                <s>了解详情</s>
              </div>
            </div>
            <div class="illustration">
              <img :src="item.imageUrl" alt="">
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { solutionItems } from '../../utils/items'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: '',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        pagination: { // 使用分页器导航
          el: '.swiper-pagination',
          clickable: true, // 点击分页器的指示点分页器会控制 Swiper 切换
        },
        loop: true, // 循环模式
        autoplay: { // 自动切换
          delay: 4000,
          disableOnInteraction: false
        },
        enabled: true, // 使用键盘切换
        onlyInViewport: true, // 仅控制当前窗口内的swiper切换
        observer: true,
        observeParents: true
      },
      items: solutionItems
    }
  }
}
</script>

<style lang="less" scoped>
  @import "./index";
</style>
