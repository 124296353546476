function setRem() {
  let clientWidth = document.documentElement.clientWidth || window.innerWidth
  document.documentElement.style.fontSize = clientWidth / 19.2 + 'px'
}

// 初始化
setRem()

// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem()
}
