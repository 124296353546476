import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 初始化样式
import 'normalize.css'
import './assets/css/reset.less'
import './assets/css/base.less'

// 引入配置文件
import './utils/rem'
import { Config } from './utils/config'
Vue.prototype.$config = Config
import { services } from './api/index'
Vue.prototype.$api = services
import Rules from './utils/rules'
Vue.prototype.$rules = Rules

// 引入用户目录 2.0
import StApp from 'st-ud-sdk/dist/app'
let st = new StApp({
  appID: '', // 微信公众号ID或支付宝公众号ID
  clientID: Config.clientID, // 项目ID
  baseURL: Config.baseUrl + '/v2/users', // api 接口地址
  mode: 'dark', // 显示风格 默认-普通模式 dark-暗黑模式
  redirectUri: Config.redirectUris[2] // 获取授权码回调地址
})
Vue.prototype.st = st

// 引入 VueFullPage
import 'fullpage.js/vendors/scrolloverflow'
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage)

// 引入 element-ui
import './utils/element'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
