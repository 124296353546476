const routers = [
  {
    path: 'users',
    name: 'users',
    component: () => import('../views/users/layout/Index'),
    redirect: '/users/apply',
    children: [
      {
        path: 'apply',
        name: 'userApply',
        component: () => import('../views/users/apply/Index'),
        meta: {
          title: '我的申请'
        }
      }
    ]
  }
]

export default { routers }
