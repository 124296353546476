<template>
  <div id="app" onselectstart="return false">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less" scoped>
  #app {
    width: 100vw;
    height: 100vh;
  }
</style>
