<template>
  <ul class="product-cards">
    <li
      v-for="(item, index) in items"
      :key="index">
      <div class="card-image" @click="details(item)">
        <img :src="item.covers" alt="">
      </div>
      <h4>{{ item.name }}</h4>
      <b
        v-if="item.type !== 2"
        @click="details(item)">
        {{ item.btnText }}
      </b>
      <el-popover
        placement="bottom"
        width="1.06rem"
        trigger="hover"
        popper-class="st-qrcode-popover"
        v-else>
        <div class="qr-code">
          <img :src="item.qrcode" alt="">
        </div>
        <b slot="reference">{{ item.btnText }}</b>
      </el-popover>
    </li>
  </ul>
</template>

<script>
export default {
  name: '',
  props: {
    items: Array
  },
  data () {
    return {}
  },
  methods: {
    details (item) {
      this.$emit('item-details', item)
    }
  }
}
</script>

<style lang="less" scoped>
  @import './index';
</style>
