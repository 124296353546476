<template>
  <div class="layout">
    <base-header @item-tap="itemTap" />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import BaseHeader from '../base-header/Index'

export default {
  name: 'Layout',
  components: {
    BaseHeader
  },
  methods: {
    itemTap(item) {
      this.$router.push({ name: item.routerName })
    }
  }
}
</script>

<style lang="less" scoped>
  .layout {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
</style>
