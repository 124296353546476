const routers = [
  {
    path: 'apply',
    name: 'apply',
    component: () => import('../views/applys/index/Index'),
    meta: {
      title: '申报流程'
    }
  },
  {
    path: 'apply-result',
    name: 'applyResult',
    component: () => import('../views/applys/result/Index'),
    meta: {
      title: '申报结果'
    }
  }
]

export default { routers }
