import axios from 'axios'
import { Config } from '../utils/config'
import { Message } from 'element-ui'

// axios 相关配置
const instance = axios.create({
  timeout: 10000
})
instance.interceptors.request.use(config => {
  return config
})
instance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    Message.error('网络错误')
    return Promise.reject(error)
  }
)

// 定义请求方法
function $get(url, data = {}) {
  return instance.get(url, { params: data })
}
function $post(url, data = {}) {
  return instance.post(url, data)
}

class Services {
  upload = {
    image: data => $post(`${Config.baseUrl}/v1/oss/upload-img`, data) // 上传图片
  }
  apply = {
    add: data => $post(`${Config.ynApiUrl}/v1/gov_data/fsc/apply/info`, data), // 提交申报
    lists: data => $get(`${Config.ynApiUrl}/v1/gov_data/fsc/apply/info`, data) // 查询申报列表
  }
}

export const services = new Services()
