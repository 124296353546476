import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { Config } from '../utils/config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routerName: JSON.parse(localStorage.getItem('router_name')) || 'home',
    userInfo: JSON.parse(localStorage.getItem('user_info')) || {}, // 用户信息
    productShow: false // 项目展示弹窗
  },
  mutations: {
    SET_ROUTER_NAME (state, data) {
      if (state !== 'login') {
        state.routerName = data
        localStorage.setItem('router_name', JSON.stringify(data))
      }
    },
    SET_USER_INFO (state, data) {
      state.userInfo = data
      localStorage.setItem('user_info', JSON.stringify(data))
    },
    SET_PRODUCT_SHOW (state, data) {
      state.productShow = data
    }
  },
  actions: {
    // 当前路由名称
    setRouterName (context, data) {
      context.commit('SET_ROUTER_NAME', data)
    },
    // 设置用户信息
    setUserInfo (context, data) {
      context.commit('SET_USER_INFO', data)
    },
    // 上传图片
    uploadImage (context, data) {
      return new Promise((resolve, reject) => {
        let param = new FormData() // 创建form对象
        param.append('dir', data.dir)
        param.append('img', data.file) // 通过 append 向 form 对象添加数据
        axios({
          method: 'post',
          url: Config.baseUrl + '/v1/oss/upload-img',
          data: param,
          processData: false,
          contentType: false
        }).then(res => {
            if (res.status === 200 && res.data.success) {
              resolve(res.data.result)
            } else {
              reject()
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 身份证识别
    idCardOcr (context, data) {
      return new Promise((resolve, reject) => {
        let param = new FormData() // 创建form对象
        param.append('typeId', data.typeId)
        param.append('file', data.file) // 通过 append 向 form 对象添加数据
        axios({
          method: 'post',
          url: Config.baseUrl + '/v1/users1/profiles/ocr/idcard',
          data: param,
          processData: false,
          contentType: false
        }).then(res => {
            if (res.status === 200 && res.data.success) {
              resolve(res.data.result)
            } else {
              reject()
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    // 营业执照识别
    businessLicenseOcr (context, data) {
      return new Promise((resolve, reject) => {
        let param = new FormData() // 创建form对象
        param.append('file', data.file) // 通过 append 向 form 对象添加数据
        axios({
          method: 'post',
          url: Config.baseUrl + '/v1/users1/profiles/ocr/business-license',
          data: param,
          processData: false,
          contentType: false
        }).then(res => {
            if (res.status === 200 && res.data.success) {
              resolve(res.data.result)
            } else {
              reject()
            }
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    setProductShow (context, data) {
      context.commit('SET_PRODUCT_SHOW', data)
    }
  },
  modules: {
  }
})
