import { Config } from './config'
import _ from 'lodash'

export function getProductImages (name, length, type) {
  return _.map(new Array(length), (item, index) => {
    index = index + 1
    if (index < 10) {
      index = '0' + index
    }
    return `${Config.ossUrl}/products/${name}-${index}.${type}`
  })
}
