import { Config } from './config'
import { getProductImages } from './images'

// 导航菜单
export const navMenus = [
  { id: 1, name: '首页', routerName: 'home' },
  { id: 2, name: '产品生态', routerName: 'products' },
  { id: 3, name: '解决方案', routerName: 'solution' },
  { id: 4, name: '合作伙伴', routerName: 'partner' },
  { id: 5, name: '关于我们', routerName: 'about' },
  { id: 6, name: '食安云', routerName: '' }
]

// 食神通项目
export const foodItems = [
  {
    name: '餐饮点餐H5-客户点餐',
    type: 1, // 1、H5  2、微信小程序  3、APP  4、PC
    covers: Config.ossUrl + '/cards/card-st-h5.png',
    images: getProductImages('food-h5', 7, 'png'),
    qrcode: Config.ossUrl + '/qrcode/food-h5.png',
    btnText: '查看详情',
  },
  {
    name: '食神通点餐机-店小二',
    type: 4,
    covers: Config.ossUrl + '/cards/card-st-pad.png',
    images: getProductImages('food-app', 9, 'png'),
    btnText: '查看详情'
  },
  {
    name: '食神通后台-大管家',
    type: 4,
    covers: Config.ossUrl + '/cards/card-st-admin.png',
    images: getProductImages('food-admin', 5, 'png'),
    btnText: '查看详情'
  },
  {
    name: '食神通老板端-大掌柜',
    type: 2,
    covers: Config.ossUrl + '/cards/card-st-boss.png',
    images: getProductImages('food-boss', 5, 'jpeg'),
    qrcode: Config.ossUrl + '/qrcode/food-boss.jpg',
    btnText: '查看详情'
  }
]

// 厨神通项目
export const cookItems = [
  {
    name: 'E农贸小程序-用户端',
    type: 2,
    covers: Config.ossUrl + '/cards/card-sp-wx.png',
    images: getProductImages('cook-user', 8, 'jpg'),
    qrcode: Config.ossUrl + '/qrcode/cook-user.jpeg',
    btnText: '扫码添加小程序'
  },
  {
    name: 'E农贸小程序-商家端',
    type: 2,
    covers: Config.ossUrl + '/cards/card-sp-business.png',
    images: getProductImages('cook-business', 9, 'png'),
    qrcode: Config.ossUrl + '/qrcode/cook-business.jpg',
    btnText: '扫码添加小程序'
  },
  {
    name: 'E农贸小程序-骑手端',
    type: 2,
    covers: Config.ossUrl + '/cards/card-sp-deliver.png',
    images: getProductImages('cook-deliver', 7, 'png'),
    qrcode: Config.ossUrl + '/qrcode/cook-deliver.jpg',
    btnText: '扫码添加小程序'
  },
  {
    name: '食安运营小程序',
    type: 2,
    covers: Config.ossUrl + '/cards/card-sp-boss.png',
    images: getProductImages('cook-boss', 7, 'png'),
    qrcode: Config.ossUrl + '/qrcode/cook-boss.png',
    btnText: '扫码添加小程序'
  }
]

// 财神通项目
export const moneyItems = [
  {
    name: '线下农批管理工具APP',
    type: 3,
    covers: Config.ossUrl + '/cards/card-yn-app.png',
    images: getProductImages('wholesale-app', 10, 'png'),
    btnText: '查看详情'
  },
  {
    name: '大宗B2B农批平台',
    type: 4,
    covers: Config.ossUrl + '/cards/card-yn-b2b.png',
    images: getProductImages('b2b-pc', 4, 'png'),
    btnText: '查看详情'
  }
]

// 解决方案
export const solutionItems = [
  {
    name: 'wholesale',
    title: '批发市场环节',
    text: '食安追溯商家版-批发商，是可供批发商对商品进行快速入库、线上报价、线上交易，并且能够把产品追溯信息传递给零售商的工具。',
    imageUrl: Config.ossUrl + '/solution-1.png'
  },
  {
    name: 'trade',
    title: '农贸市场环节',
    text: '食安追溯商家版-零售商，是农贸环节中的肉菜零售商专用APP/小程序工具，能帮助商家快速完成商品进场登记、报价、上架，让商家打通线上线下业务。',
    imageUrl: Config.ossUrl + '/solution-2.png'
  },
  {
    name: 'restaurant',
    title: '餐饮环节',
    text: '食神通是一个可以让消费者了解食材溯源信息的点餐系统，能让消费者知道食材“从田头到餐桌”的全过程，更放心地享用餐厅的菜品。',
    imageUrl: Config.ossUrl + '/solution-3.png'
  },
  {
    name: 'gov',
    title: '政府监管',
    text: '溯源大数据政府版是一个为各级政府部门提供监控、统计可视化市场中流通的农产品价格、数量的在线平台。为应对商务部与大数据管理局的绩效考核提供统计数据的依据。',
    imageUrl: Config.ossUrl + '/solution-4.png'
  }
]

// 合作伙伴
export const partners = [
  [
    {
      name: '杭州市商务局',
      icon: Config.ossUrl + '/partner-01.png'
    },
    {
      name: '广州市商务局',
      icon: Config.ossUrl + '/partner-02.png'
    },
    {
      name: '杭州联合银行',
      icon: Config.ossUrl + '/partner-03.png'
    },
    {
      name: '喜马拉雅',
      icon: Config.ossUrl + '/partner-04.png'
    },
    {
      name: '美团外卖',
      icon: Config.ossUrl + '/partner-05.png'
    },
    {
      name: '饿了么',
      icon: Config.ossUrl + '/partner-06.png'
    },
    {
      name: '中国农业银行',
      icon: Config.ossUrl + '/partner-07.png'
    },
    {
      name: '中国建设银行',
      icon: Config.ossUrl + '/partner-08.png'
    }
  ],
  [
    {
      name: '地利集团',
      icon: Config.ossUrl + '/partner-09.png'
    },
    {
      name: '康爱多',
      icon: Config.ossUrl + '/partner-10.png'
    },
    {
      name: '中国银联',
      icon: Config.ossUrl + '/partner-11.png'
    },
    {
      name: '支付宝',
      icon: Config.ossUrl + '/partner-12.png'
    },
    {
      name: '阿里云',
      icon: Config.ossUrl + '/partner-13.png'
    },
    {
      name: '仲恺农业工程学院',
      icon: Config.ossUrl + '/partner-14.png'
    },
    {
      name: '点我达',
      icon: Config.ossUrl + '/partner-15.png'
    },
    {
      name: '顺丰快递',
      icon: Config.ossUrl + '/partner-16.png'
    }
  ]
]
